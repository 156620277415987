import * as React from 'react'
import { Fragment, useState } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Dialog,
  Transition,
} from '@headlessui/react'
import {
  XIcon,
} from '@heroicons/react/outline'
import {
  FilterIcon,
} from '@heroicons/react/solid'
import Layout from '../components/Layout'

export default function Categories({ data, pageContext }) {
  const products = data.products.nodes
  const categories = data.categories.nodes
  const seoMetaTags = data.category.seoMetaTags

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  return (
    <Layout seoMetaTags={seoMetaTags}>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">
                  Categorías
                </h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                <h3 className="sr-only">Categories</h3>
                <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                  {categories.map(category => (
                    <li key={category.name}>
                      <Link
                        to={`/productos/${category.slug}`}
                        className="block px-2 py-3"
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative z-10 flex justify-between border-b border-gray-200 pt-24 pb-6">
          <h1 className="sr-only">Productos | {pageContext.name}</h1>

          <nav aria-label="Breadcrumb" className="text-base sm:text-3xl">
            <ol role="list" className="flex items-center space-x-4">
              <li key="category">
                <div className="flex items-center">
                  <span className="mr-4 font-medium text-gray-500">
                    Productos
                  </span>
                  <svg
                    viewBox="0 0 6 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-auto text-gray-300 sm:h-10"
                  >
                    <path
                      d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </li>
              <li className="">
                <span aria-current="page" className="font-medium text-gray-900">
                  {pageContext.name}
                </span>
              </li>
            </ol>
          </nav>

          <div className="flex items-center">
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <span className="sr-only">Categorias</span>
              <FilterIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <section aria-labelledby="products-heading" className="pt-6 pb-24">
          <h2 id="products-heading" className="sr-only">
            Categorías
          </h2>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            {/* Filters */}
            <form className="hidden lg:block">
              <h3 className="sr-only">Categorías</h3>
              <ul
                role="list"
                className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-slate-500"
              >
                {categories.map(category => (
                  <li key={category.name}>
                    {category.id === pageContext.id ? (
                      <span className="text-sky-500">{category.name}</span>
                    ) : (
                      <Link
                        to={`/productos/${category.slug}`}
                        className=" hover:text-slate-900"
                      >
                        {category.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </form>

            {/* Product grid */}
            <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-3 lg:col-span-3 lg:gap-x-8">
              <div
                  className="prose prose-sm mt-4 text-gray-500 lg:col-span-3"
                  dangerouslySetInnerHTML={{
                    __html: pageContext.description,
                  }}
                />

              {products.map(product => (
                <Link
                  key={product.name}
                  to={`/productos/${product.category.slug}/${product.slug}`}
                  className="group text-base"
                >
                  <div className="aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                    {product.photo && (
                      <GatsbyImage
                        image={{ ...product.photo.gatsbyImageData }}
                        alt="foto"
                        className="h-full w-full object-cover object-center"
                      />
                    )}
                  </div>
                  <h3 className="mt-4 font-medium text-gray-800 hover:text-sky-900">
                    {product.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {product.descriptionNode.childMarkdownRemark.excerpt}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    products: allDatoCmsProduct(filter: { category: { id: { eq: $id } } }) {
      nodes {
        name
        descriptionNode {
          childMarkdownRemark {
            excerpt
          }
        }
        photo {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        category {
          slug
        }
        slug
      }
    }

    categories: allDatoCmsCategory {
      nodes {
        id
        name
        slug
      }
    }

    category: datoCmsCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
